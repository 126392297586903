import { Engine } from '../../engine/engine';
import { useEffect, useState } from 'react';
import { useBlock } from './useBlock';
import { Action, ActionValue } from '@baseModel/document/blocks/action';

const engine = Engine.getInstance();

function getActionById(customNodeId: string | undefined) {
  return customNodeId ? engine.getDocument().getActionById(customNodeId) : undefined;
}

/**
 * @param actionId
 */
export function useAction(actionId: string | undefined): ReturnType<typeof useBlock<Action, ActionValue | undefined>> {
  const [action, setAction] = useState(getActionById(actionId));
  useEffect(() => {
    setAction(getActionById(actionId));
  }, [actionId, setAction]);
  const [customNodeBlock, setValue] = useBlock<Action, ActionValue | undefined>(action);
  return [customNodeBlock, setValue];
}

import { SimpleValueType } from '../types/simpleValueType';
import { Engine } from '../engine/engine';
import { Models } from '../engine/types';
import { MetaModel } from '../metaModel/metaModel';
import { DataJuggler, DataStoreName, EmptyObject, JSONSerialize } from '../utils/dataJuggler';
import { Listener, Unsubscriber } from '@utils/observable';
import { ModelError } from '../errors';

export interface JSONSerializeModel<C, R> extends JSONSerialize<SimpleValueType, C, R> {
  metaModelName: string;
  idFieldName?: string;
  id?: string;
}

export abstract class Model<K extends MetaModel<MR>, C = never, R = never, MR = never> extends DataJuggler<
  SimpleValueType,
  C,
  R
> {
  protected engine: Engine | undefined;

  // TODO избыточные данные, проверить необходимость
  public abstract readonly modelType: Models;

  constructor(protected readonly metaModel: K) {
    super(metaModel.getName());
  }

  public getMetaModel() {
    return this.metaModel;
  }

  public linkToEngine(engine: Engine) {
    if (this.engine) {
      throw new ModelError(`Модель уже добавлена в engine`);
    }
    this.engine = engine;
    this.validateData();
  }

  public getIdFieldName(): string | undefined {
    return this.metaModel.getIdFieldName();
  }

  public getId(): string | undefined {
    const idFieldName = this.getIdFieldName();
    if (idFieldName) {
      const id = this.getFieldValue(idFieldName);
      if (!id) {
        this.logger('id not set');
      }
      return id ? id.toString() : undefined;
    }
    this.logger('no id field found');
  }

  /**
   * Проверим целиком модель
   */
  public override validateData() {
    super.validateData();
    const id = this.getId();
    if (!id) {
      throw new ModelError('primary-key не установлен');
    }
  }

  public deleteMe() {
    // TODO: delete all listeners
    this.logger('delete me');
  }

  public getValueRefByPath(pathStr: string): {
    getter: () => SimpleValueType | C | R | undefined;
    subscribe: (
      listener: Listener<SimpleValueType | undefined> | Listener<C | undefined> | Listener<R | undefined>,
      sender?: string | EmptyObject
    ) => Unsubscriber;
    meta: {
      modelName?: string;
      dataStoreName: DataStoreName;
      fieldName: string;
      modelType: Models;
      type?: string;
    };
  } {
    if (!pathStr) {
      throw new ModelError(`path is empty`);
    }
    const path = pathStr.split('.');
    const pathPart = path[0];
    const metaModel = this.getMetaModel();
    const isFieldName = metaModel.getFieldNames().find((el) => pathPart === el);
    if (isFieldName) {
      if (path.length > 2) {
        console.error('нашли в field, но путь длиннее' + pathStr);
      }
      return {
        getter: this.getFieldValue.bind(this, pathPart),
        subscribe: this.subscribeData.bind(this, DataStoreName.fields, pathPart),
        meta: {
          modelName: this.getId(),
          dataStoreName: DataStoreName.fields,
          fieldName: pathPart,
          modelType: this.modelType,
          type: metaModel.getFieldValue(pathPart)?.type
        }
      };
    }
    const isRelationName = metaModel.getRelationNames().find((el) => pathPart === el);
    if (!isRelationName) {
      const relation = this.getRelationValue(pathPart);
      if (relation) {
        if (!this.engine) {
          throw new ModelError(`Модель не связана с Engine`);
        }
        const linkedModel = this.engine.getEntityById(relation.toString());
        return linkedModel.getValueRefByPath(path.slice(1).join('.'));
      }
    }
    const isCommonName = metaModel.getCommonNames().find((el) => pathPart === el);
    if (isCommonName) {
      if (path.length > 2) {
        console.error('нашли в common, но путь длиннее' + pathStr);
      }
      return {
        getter: this.getCommonValue.bind(this, pathPart),
        subscribe: this.subscribeData.bind(this, DataStoreName.common, pathPart),
        meta: {
          modelName: this.getId(),
          dataStoreName: DataStoreName.common,
          fieldName: pathPart,
          modelType: this.modelType
        }
      };
    }

    throw new ModelError(`имя не найдено для пути ` + pathStr);
  }

  public override toJSON(): JSONSerializeModel<C, R> {
    const jugglerSerialize = super.toJSON();
    return {
      ...jugglerSerialize,
      metaModelName: this.getMetaModel().getName(),
      idFieldName: this.getIdFieldName(),
      id: this.getId()
    };
  }
}

import '@smwb/summer-ui/styles/normalize.css';
import '@smwb/summer-ui/styles/css/summer-ui.css';
import 'prosemirror-example-setup/style/style.css';
import 'prosemirror-menu/style/menu.css';
import { ErrorBoundary } from 'react-error-boundary';
import './styles/globalStyles.css';
import './styles/table.less';
import './styles/textField.less';

import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter, Navigate } from 'react-router-dom';
import { Layout } from '@components/layout/layout';
import { Document } from '@pages/document/document';
import { SnackbarProvider } from '@smwb/summer-ui';
import { Urls } from './routes';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { FallbackRender } from '@components/fallbackRender/fallbackRender';

const isDev = process.env.REACT_APP_USE_HASH_ROUTER === '1' && process.env.PUBLIC_URL;

export function App() {
  const RouterAPI = isDev ? HashRouter : BrowserRouter;

  return (
    <ErrorBoundary
      fallbackRender={FallbackRender}
      onReset={(details) => {
        console.log('ErrorBoundary details', details);
      }}
    >
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <SnackbarProvider>
            <RouterAPI>
              <Routes>
                <Route path={isDev ? process.env.PUBLIC_URL : '/'} element={<Layout />}>
                  <Route path={Urls.documents} element={<Document />} />
                </Route>
                <Route
                  path="/*"
                  element={<Navigate to={`${isDev ? process.env.PUBLIC_URL : ''}/${Urls.documents}`} />}
                />
              </Routes>
            </RouterAPI>
          </SnackbarProvider>
        </DndProvider>
      </Provider>
    </ErrorBoundary>
  );
}

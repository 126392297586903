import { ModelEventTypes, Models } from '@baseModel/engine/types';
import { Paths } from '@commonTypes/index';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import kebabCase from 'lodash/kebabCase';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { emitYaml } from '@components/yamlEditor/plugins/yamlBind/editorInternalChangesSubscriber';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { YamlModelState } from '@components/yamlEditor/yamlModelState';
import { Engine } from '@baseModel/engine/engine';
import { modelPathsMapping } from '@components/yamlEditor/const';
import { JsonEntity, JsonMetaEntity, JsonMetaRelation, JsonRelation } from '@baseModel/types/jsonDescription';
import { EmitType } from '../types';

const yamlModelState = YamlModelState.getInstance();
const engine = Engine.getInstance();

function getModel(model: Models, key: string) {
  switch (model) {
    case Models.EntityMetaModel:
      return engine.getMetaEntityByName(key);
    case Models.RelationMetaModel:
      return engine.getMetaRelationByName(key);
    case Models.Entity:
      return engine.getEntityById(key);
    case Models.Relation:
      return engine.getRelationById(key);
  }
}

export function baseModelModelsSubscriber(model: Models) {
  const modelPath = modelPathsMapping[model];

  const updateSubscribe = engine.subscribe(
    model,
    ModelEventTypes.update,
    (value) => {
      console.log('ddd', model, value);
      const { id, name, data } = value.data;
      const modelKey = id ?? name;

      if (modelKey === undefined) {
        return;
      }

      // TODO Paths типизировать по полям [string (id | name), 'common' | 'fields' | 'end', string (fieldName)]
      const path: Paths = [modelKey];

      const kebabFieldName = kebabCase(data.fieldName);

      if (model === Models.Entity) {
        path.push(kebabFieldName);
      } else {
        if (data.fieldType === DataStoreName.fields) {
          path.push('fields', data.fieldName);
        } else if (data.fieldType === DataStoreName.relations) {
          path.push('ends', data.fieldName);
        }
      }

      if (data.itsNewUnit) {
        yamlModelState.updateState(model, path, data.value ?? '');
        emitYaml(EmitType.Add, modelPath, path, data.value);
      } else if (data.value === undefined) {
        yamlModelState.removeFromState(model, modelKey);
        emitYaml(EmitType.Remove, modelPath, path);
      } else {
        yamlModelState.updateState(model, path, data.value);
        emitYaml(EmitType.Update, modelPath, path, data.value);
      }
    },
    EDITOR_SENDER
  );

  const removeSubscribe = engine.subscribe(
    model,
    ModelEventTypes.remove,
    (value) => {
      const { id, name } = value.data;
      const modelKey = id ?? name;

      if (modelKey === undefined) {
        return;
      }
      yamlModelState.removeFromState(model, modelKey);
      emitYaml(EmitType.Remove, modelPath, modelKey);
    },
    EDITOR_SENDER
  );

  const addSubscribe = engine.subscribe(
    model,
    ModelEventTypes.add,
    (value) => {
      const { id, name } = value.data;
      const modelKey = id ?? name;

      if (modelKey === undefined) {
        return;
      }

      const modelInstance = getModel(model, modelKey);

      const json = modelInstance.toJSON();
      let data: Record<string, unknown> = {};

      if (modelInstance instanceof Entity) {
        data = json.fields;
      } else {
        if (modelInstance instanceof Relation && 'metaModelName' in json) {
          data.type = json.metaModelName;
        }

        for (const commonKey in json.common) {
          data[kebabCase(commonKey)] = json.common[commonKey];
        }

        if (modelInstance instanceof Relation || modelInstance instanceof RelationMetaModel) {
          data.ends = json.relations;
        }
        data.fields = json.fields;
      }

      if (modelInstance instanceof EntityMetaModel || modelInstance instanceof RelationMetaModel) {
        emitYaml(EmitType.Add, modelPath, modelKey, data);
      } else {
        emitYaml(EmitType.Add, modelPath, [], data);
      }
      yamlModelState.addToState(model, modelKey, <JsonEntity | JsonRelation | JsonMetaEntity | JsonMetaRelation>data);
    },
    EDITOR_SENDER
  );

  const unsubscribes = [updateSubscribe, removeSubscribe, addSubscribe];

  return () => {
    unsubscribes.forEach((el) => el());
  };
}

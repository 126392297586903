import { Engine } from '@baseModel/engine/engine';
import { ENTITY_MODEL, FIELD_MODEL, RELATION_MODEL } from '@baseModel/basisModel/const';
import debug, { Debugger } from 'debug';
import { Relation } from '@baseModel/model/relation';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { JsonField, JsonSource } from '@baseModel/types/jsonDescription';
import { toYAML } from '@baseModel/utils/toYAML';
import { Entity } from '@baseModel/model/entity';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { Models } from '@baseModel/engine/types';
import axios from 'axios';
import config from '../../../config';

/**
 * Генерация метамодели по базисной модели
 * @param engine
 */
export class GenerateMetaModel {
  protected readonly logger: Debugger;
  private readonly entityMetaModelsNames: string[];
  private readonly relationMetaModelsNames: string[];

  private readonly engineWorkInstanceId = Math.random().toString(36);

  private readonly idField: JsonField = {
    type: 'string',
    unique: true,
    'primary-key': true,
    'display-name': 'id',
    required: true
  };

  constructor(private readonly engine: Engine) {
    this.logger = debug(`generate-meta-model`);
    this.logger('created');
    this.entityMetaModelsNames = engine.getEntityMetaModelsNames();
    this.relationMetaModelsNames = engine.getRelationMetaModelsNames();
  }

  private getEngineWorkInstance(): Engine {
    return Engine.getInstance(this.engineWorkInstanceId);
  }

  private destroyEngineWorkInstance(): void {
    Engine.destroyInstance(this.engineWorkInstanceId);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  private async modelCheck() {
    this.logger('modelCheck start');
    const modelNames = [ENTITY_MODEL.name, RELATION_MODEL.name, FIELD_MODEL.name];
    modelNames.forEach((modelName) => {
      if (!this.entityMetaModelsNames.find((el) => el === modelName)) {
        throw new Error(`Не найдена модель ${modelName} в базисной модели`);
      }
    });
    const relationNames = [ENTITY_MODEL.fieldRelation, RELATION_MODEL.fieldRelation, RELATION_MODEL.entityRelation];
    relationNames.forEach((relationName) => {
      if (!this.relationMetaModelsNames.find((el) => el === relationName)) {
        throw new Error(`Не найдена модель ${relationName} в базисной модели`);
      }
    });
    this.logger('modelCheck success');
  }

  /**
   * Получим список связей полей сгруппированных по entityId
   * @private
   */
  private getEntityFields(entityType: string, entityEndName: string) {
    const fieldRelationIds = this.engine.getRelationsIdsByMetaName(entityType);
    const fieldRelations: { [entityId: string]: Relation[] } = {};
    fieldRelationIds.forEach((fieldRelationId) => {
      const fieldRelation = this.engine.getRelationById(fieldRelationId);
      const entityId = fieldRelation.getRelationValue(entityEndName);
      if (!entityId || typeof entityId !== 'string') {
        const error = `entityId для fieldRelationId ${fieldRelationId} undefined ${fieldRelation.toString()}`;
        this.logger(error);
        throw new Error(error);
      }
      if (!fieldRelations[entityId]) {
        fieldRelations[entityId] = [];
      }
      fieldRelations[entityId].push(fieldRelation);
    });
    return fieldRelations;
  }

  private getFieldValueFromEntityField(field: Entity): { name: string; value: JsonField } {
    const name = field.getFieldValue('name');
    if (!name || typeof name !== 'string') {
      const error = `name undefined field ${field.toString()}`;
      this.logger(error);
      throw new Error(error);
    }
    let fieldType = field.getFieldValue('fieldType');
    if (!fieldType || typeof fieldType !== 'string') {
      this.logger(`fieldType undefined field ${field.toString()} default string`);
      fieldType = 'string';
    }
    const displayName = (field.getFieldValue('display-name') || name).toString();

    const entityField: JsonField = {
      type: fieldType,
      'display-name': displayName
    };
    const fieldRequired = field.getFieldValue('required');
    if (fieldRequired) {
      entityField.required = fieldRequired === 1 || fieldRequired === true;
    }
    const description = field.getFieldValue('description');
    if (description !== undefined) {
      entityField.description = description.toString();
    }
    const multiply = field.getFieldValue('multiply');
    if (multiply) {
      entityField.multiply = multiply === 1 || multiply === true;
    }
    return { name, value: entityField };
  }

  /**
   * Создадим Entity модели
   */
  private generateMeta(metaType: Models.EntityMetaModel | Models.RelationMetaModel) {
    const configConst = metaType === Models.EntityMetaModel ? ENTITY_MODEL : RELATION_MODEL;
    const entityModelIds = this.engine.getEntitiesIdsByMetaName(configConst.name);
    this.logger(`найдено ${entityModelIds.length} entity Models`);
    if (!entityModelIds.length) {
      const error = `Модель не содержит ни одной Entity типа ${configConst.name}`;
      this.logger(error);
      throw new Error(error);
    }

    const entityRelations = this.getEntityFields(RELATION_MODEL.entityRelation, RELATION_MODEL.relationEndName);
    const fieldRelations = this.getEntityFields(configConst.fieldRelation, configConst.fieldEndName);
    entityModelIds.forEach((entityModelId) => {
      const entity = this.engine.getEntityById(entityModelId);
      const entityFieldsRelations = fieldRelations[entityModelId] || [];
      const fields = entityFieldsRelations.map((entityFieldsRelation) => {
        const entityFieldId = entityFieldsRelation.getRelationValue(FIELD_MODEL.fieldEndName);
        if (!entityFieldId || typeof entityFieldId !== 'string') {
          const error = `entityFieldId для fieldRelationId ${entityFieldsRelation.getId()} undefined ${entityFieldsRelation.toString()}`;
          this.logger(error);
          throw new Error(error);
        }
        return this.engine.getEntityById(entityFieldId);
      });
      const entityName = entity.getFieldValue(configConst.commonName);
      if (!entityName || typeof entityName !== 'string') {
        const error = `entityName для entity ${entity.getId()} undefined ${entity.toString()}`;
        this.logger(error);
        throw new Error(error);
      }
      const metaEntity: EntityMetaModel | RelationMetaModel =
        metaType === Models.EntityMetaModel ? new EntityMetaModel(entityName) : new RelationMetaModel(entityName);
      metaEntity.setDisplayName(entity.getCommonValue(ENTITY_MODEL.displayName) || entityName);
      metaEntity.setCommonValue(
        ENTITY_MODEL.description,
        (entity.getFieldValue(ENTITY_MODEL.description) || '').toString()
      );
      metaEntity.setFieldValue('id', this.idField);
      fields.forEach((field) => {
        const { name, value } = this.getFieldValueFromEntityField(field);
        metaEntity.setFieldValue(name, value);
      });
      if (metaEntity instanceof EntityMetaModel) {
        this.getEngineWorkInstance().addEntityMetaModel(metaEntity);
      } else {
        const entityEndsRelations = entityRelations[entityModelId] || [];
        entityEndsRelations.forEach((entityEndsRelation) => {
          const relatedEntityId = entityEndsRelation.getRelationValue(RELATION_MODEL.entityEndName);
          if (!relatedEntityId || typeof relatedEntityId !== 'string') {
            const error = `entity id undefined для связи ${entityEndsRelation.toString()}`;
            this.logger(error);
            throw new Error(error);
          }
          const relatedEntity = this.engine.getEntityById(relatedEntityId);
          // Мы уже знаем, что name есть
          const name = relatedEntity.getFieldValue(ENTITY_MODEL.commonName) as string;
          const endName = entityEndsRelation.getFieldValue('name');
          if (!endName || typeof endName !== 'string') {
            const error = `end name undefined для связи ${entityEndsRelation.toString()}`;
            this.logger(error);
            throw new Error(error);
          }
          const value: JsonSource = {
            type: name,
            'display-name': (entityEndsRelation.getFieldValue('display-name') || name).toString(),
            description: (entityEndsRelation.getFieldValue('description') || '').toString()
          };
          metaEntity.setRelationValue(endName, value);
        });
        this.getEngineWorkInstance().addRelationMetaModel(metaEntity);
      }
      this.logger(`new meta ${metaEntity.toString()}`);
    });
  }

  private async saveModel() {
    const yaml = toYAML(this.getEngineWorkInstance().toJSON());
    const blob = new Blob([yaml], { type: 'text/plain;charset=utf-8' });
    const id = Date.now().toString(36);
    const data = new File([blob], 'config.yaml');
    const response = await axios.put(`${config.apiS3Endpoint}/${id}`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (response.status !== axios.HttpStatusCode.Ok) {
      throw new Error('Ошибка сохранения ');
    }

    return id;
  }

  /**
   * Создать метамодель, на основе текущей базисной модели
   */
  public async generate() {
    this.logger('generate start');
    this.getEngineWorkInstance();
    try {
      await this.modelCheck();
      this.generateMeta(Models.EntityMetaModel);
      this.generateMeta(Models.RelationMetaModel);
      return await this.saveModel();
    } finally {
      this.destroyEngineWorkInstance();
    }
    this.logger('generate success');
  }
}

import { Models } from '@baseModel/engine/types';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { Engine } from '@baseModel/engine/engine';
import { FieldConfig } from '@components/metaTableView/utils/fieldsColumns';

const engine = Engine.getInstance();

export function fieldTypeValidate(modelType?: Models.EntityMetaModel | Models.RelationMetaModel) {
  return (value: string) => {
    if (modelType === undefined) {
      return undefined;
    }

    const availableValues = [DataStoreName.common, DataStoreName.fields];

    if (modelType === Models.RelationMetaModel) {
      availableValues.push(DataStoreName.relations);
    }

    if (!availableValues.includes(value as DataStoreName)) {
      return 'Выберите один из типов полей.';
    }
  };
}

function getFieldNames(model: EntityMetaModel | RelationMetaModel, fieldType: DataStoreName) {
  switch (fieldType) {
    case DataStoreName.common:
      return model.getCommonNames();
    case DataStoreName.fields:
      return model.getFieldNames();
    case DataStoreName.relations:
      return model.getRelationNames();
  }
}

export function fieldNameValidate(
  modelType?: Models.EntityMetaModel | Models.RelationMetaModel,
  modelName?: string,
  fieldType?: DataStoreName
) {
  return (value: string) => {
    if (modelType === undefined || modelName === undefined || fieldType === undefined) {
      return;
    }

    if (!value) {
      return 'Обязательное поле.';
    }

    if (!/^[a-z0-9-]+$/i.test(value)) {
      return 'Имя может содержать только буквы латинского алфавита и цифры ([a-zA-Z0-9-]).';
    }

    const model =
      modelType === Models.EntityMetaModel
        ? engine.getMetaEntityByName(modelName)
        : engine.getMetaRelationByName(modelName);
    const fieldNames = getFieldNames(model, fieldType);

    if (fieldNames.includes(value)) {
      return 'Поле с таким именем уже существует.';
    }
  };
}

export function fieldValidate(config: FieldConfig) {
  return (value: string) => {
    if (config.isRequired && !value) {
      return 'Обязательное поле.';
    }
  };
}

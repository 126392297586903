import { Schema } from 'yup';
import { Engine } from '@baseModel/engine/engine';
import { Listener, Observable, Unsubscriber } from '@utils/observable';
import { ModelError } from '@baseModel/errors';
import { AnyObject, EmptyObject } from '@baseModel/utils/dataJuggler';

export interface JSONSerializeBlock<T> {
  type: string;
  id: string;
  value?: T;
  sortIndex: number;
}

export abstract class BaseBlock<T> {
  public static valueSchema: Schema;

  protected engine: Engine | undefined;

  private observable: Observable<T | undefined>;

  private sortIndex: Observable<number | undefined> = new Observable(`baseBlock_sortIndex`);

  protected constructor(valueSchema: Schema, public readonly type: string, private readonly id: string) {
    if (!id) {
      throw new ModelError('id должен быть задан');
    }

    BaseBlock.valueSchema = valueSchema;

    this.observable = new Observable(this.type);
  }

  public linkToEngine(engine: Engine) {
    if (this.engine) {
      throw new ModelError(`Модель уже добавлена в engine`);
    }

    this.engine = engine;
    this.validateValue(this.getValue());
  }

  public setSortIndex(sortIndex: number, sender: string | EmptyObject): void {
    if (sortIndex < 0) {
      throw new ModelError(
        `Сортировка не может быть меньше нуля, передано ${sortIndex} для ${JSON.stringify(this.toJSON())}`
      );
    }

    this.sortIndex.setValue(sortIndex, sender);
  }

  public getSortIndex(): number {
    const sortIndex = this.sortIndex.value;

    if (!sortIndex && sortIndex !== 0) {
      throw new ModelError(`sortIndex is undefined ${JSON.stringify(this.getValue())}`);
    }

    return sortIndex;
  }

  public getId(): string {
    return this.id;
  }

  public getValue(): T | undefined {
    return this.observable.value;
  }

  public validateBlock(): void {
    const id = this.getId();

    if (!id) {
      throw new ModelError('У блока не найден ID');
    }
  }

  public validateValue(value?: T): void {
    if (!value) {
      return;
    }

    // BaseBlock.valueSchema.validateSync(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getValueFromJSON(json: string | AnyObject) {
    // need impl
    throw new ModelError('getValueFromJSON не реализован');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getValueInJSONForYAML(json: string | AnyObject) {
    // need impl
    throw new ModelError('getValueInJsonForYAML не реализован');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static fromJSON(json: string | AnyObject) {
    // need impl
    throw new ModelError('fromJSON не реализован');
  }

  public setValue(value?: T, sender?: string | EmptyObject) {
    this.validateValue(value);
    this.observable.setValue(value, sender);
  }

  public subscribe(listener: Listener<T | undefined>, sender: string | EmptyObject): Unsubscriber {
    return this.observable.subscribe(listener, sender);
  }

  public sortIndexSubscribe(listener: Listener<number | undefined>, sender: string | EmptyObject): Unsubscriber {
    return this.sortIndex.subscribe(listener, sender);
  }

  public deleteMe() {
    this.observable.unsubscribeAll();
  }

  abstract getMarkdown(): string;

  public toJSON(): JSONSerializeBlock<T> {
    return {
      type: this.type,
      id: this.getId(),
      value: this.getValue(),
      sortIndex: this.getSortIndex()
    };
  }
}

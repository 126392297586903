import {
  JsonDescription,
  JsonDocumentBlocks,
  JsonEntity,
  JsonField,
  JsonMetaEntities,
  JsonMetaEntity,
  JsonMetaRelation,
  JsonMetaRelations,
  JsonRelation,
  JsonRootBlock,
  ModelType
} from '@baseModel/types/jsonDescription';
import { Engine } from '@baseModel/engine/engine';
import { toYAML } from '@baseModel/utils/toYAML';
import { Models } from '@baseModel/engine/types';
import { getId } from '@components/yamlEditor/utils/getId';
import omit from 'lodash/omit';
import { Paths } from '@commonTypes/index';
import { AnyObject } from 'react-final-form';
import { SimpleValueType } from '@baseModel/types/simpleValueType';
import { getJsonObject } from '@components/yamlEditor/utils/getJsonObject';

const engine = Engine.getInstance();

export interface BlocksReplacer<T> {
  (oldState: T): T;
}

// TODO можно хранить инфу по ид отдельно в Set и/или сразу позиции и т д
export class YamlModelState {
  private static instance: YamlModelState | undefined;

  private stateYaml: string | undefined;
  private stateJS: JsonDescription | undefined;

  // Здесь надо присвоить в стей новое значение и перезагрузить модель реактора
  public reloadStateFromEngine() {
    this.stateYaml = toYAML(engine.toJSON());
    // этот ямл точно валидный, иначе упало бы раньше
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.stateJS = getJsonObject(this.stateYaml)!;
  }

  public static getInstance(): YamlModelState {
    if (!YamlModelState.instance) {
      YamlModelState.instance = new YamlModelState();
    }
    return YamlModelState.instance;
  }

  public getJs(): JsonDescription {
    if (!this.stateJS) {
      this.reloadStateFromEngine();
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.stateJS!;
  }

  public getDocument(): JsonDocumentBlocks[] {
    return this.getJs()[JsonRootBlock.document];
  }

  public getEntities(): JsonEntity[] {
    return this.getJs()[JsonRootBlock.model][ModelType.entities];
  }

  public setEntities(jsonEntities: JsonEntity[] | BlocksReplacer<JsonEntity[]>): void {
    if (typeof jsonEntities === 'function') {
      this.getJs()[JsonRootBlock.model][ModelType.entities] = jsonEntities(this.getEntities());
    } else {
      this.getJs()[JsonRootBlock.model][ModelType.entities] = jsonEntities;
    }
  }

  public setModels(model: Models.Entity, jsonReplacer: BlocksReplacer<JsonEntity[]>): void;
  public setModels(model: Models.Relation, jsonReplacer: BlocksReplacer<JsonRelation[]>): void;
  public setModels(model: Models.EntityMetaModel, jsonReplacer: BlocksReplacer<JsonMetaEntities>): void;
  public setModels(model: Models.RelationMetaModel, jsonReplacer: BlocksReplacer<JsonMetaRelations>): void;
  public setModels(
    model: Models,
    jsonReplacer:
      | BlocksReplacer<JsonEntity[]>
      | BlocksReplacer<JsonRelation[]>
      | BlocksReplacer<JsonMetaEntities>
      | BlocksReplacer<JsonMetaRelations>
  ): void {
    if (model === Models.Entity) {
      this.getJs()[JsonRootBlock.model][ModelType.entities] = (<BlocksReplacer<JsonEntity[]>>jsonReplacer)(
        this.getEntities()
      );
      return;
    }
    if (model === Models.Relation) {
      this.getJs()[JsonRootBlock.model][ModelType.relations] = (<BlocksReplacer<JsonRelation[]>>jsonReplacer)(
        this.getRelations()
      );
      return;
    }
    if (model === Models.EntityMetaModel) {
      this.getJs()[JsonRootBlock.metamodel][ModelType.entities] = (<BlocksReplacer<JsonMetaEntities>>jsonReplacer)(
        this.getMetaEntities()
      );
      return;
    }
    if (model === Models.RelationMetaModel) {
      this.getJs()[JsonRootBlock.metamodel][ModelType.relations] = (<BlocksReplacer<JsonMetaRelations>>jsonReplacer)(
        this.getMetaRelations()
      );
      return;
    }
  }

  public addToState(
    model: Models,
    modelKey: string,
    value: JsonEntity | JsonRelation | JsonMetaEntity | JsonMetaRelation
  ) {
    if (model === Models.Entity) {
      this.setModels(Models.Entity, (entities) => {
        entities.push(<JsonEntity>value);
        return entities;
      });
    } else if (model === Models.Relation) {
      this.setModels(Models.Relation, (relations) => {
        relations.push(<JsonRelation>value);
        return relations;
      });
    } else if (model === Models.EntityMetaModel) {
      this.setModels(Models.EntityMetaModel, (entities) => {
        entities[modelKey] = <JsonMetaEntity>value;
        return entities;
      });
    } else if (model === Models.RelationMetaModel) {
      this.setModels(Models.RelationMetaModel, (relations) => {
        relations[modelKey] = <JsonMetaRelation>value;
        return relations;
      });
    }
  }

  public removeFromState(model: Models, modelKey: string) {
    if (model === Models.Entity) {
      this.setModels(Models.Entity, (entities) => {
        return entities.filter((el) => getId(el) !== modelKey);
      });
    } else if (model === Models.Relation) {
      this.setModels(Models.Relation, (relations) => {
        return relations.filter((el) => getId(el) !== modelKey);
      });
    } else if (model === Models.EntityMetaModel) {
      this.setModels(Models.EntityMetaModel, (entities) => {
        return omit(entities, modelKey);
      });
    } else if (model === Models.RelationMetaModel) {
      this.setModels(Models.RelationMetaModel, (relations) => {
        return omit(relations, modelKey);
      });
    }
  }

  public updateState(model: Models, path: Paths, value: string | number | boolean | AnyObject | null) {
    if (model === Models.Entity) {
      this.setModels(Models.Entity, (entities) => {
        const [id, fieldName] = path;
        const index = entities.findIndex((el) => getId(el) !== id);
        if (value === null || value === undefined) {
          delete entities[index][fieldName];
        } else {
          entities[index][fieldName] = <SimpleValueType>value;
        }
        return entities;
      });
    } else if (model === Models.Relation) {
      this.setModels(Models.Relation, (relations) => {
        const [id, fieldType, fieldName] = path;
        const index = relations.findIndex((el) => getId(el) !== id);
        if (value === null || value === undefined) {
          delete relations[index][<'fields' | 'ends'>fieldType][fieldName];
        } else {
          relations[index][<'fields' | 'ends'>fieldType][fieldName] = <SimpleValueType>value;
        }
        return relations;
      });
    } else if (model === Models.EntityMetaModel) {
      this.setModels(Models.EntityMetaModel, (entities) => {
        const [name, fieldType, fieldName] = path;
        const entity = entities[name];
        if (fieldName) {
          if (value === null || value === undefined) {
            delete entity['fields'][fieldName];
          } else {
            entity['fields'][fieldName] = <JsonField>value;
          }
        } else {
          if (value === null || value === undefined) {
            delete entity[<'display-name' | 'style'>fieldType];
          } else {
            entity[<'display-name' | 'style'>fieldType] = <string>value;
          }
        }
        return entities;
      });
    } else if (model === Models.RelationMetaModel) {
      this.setModels(Models.RelationMetaModel, (relations) => {
        const [name, fieldType, fieldName] = path;
        const relation = relations[name];
        if (fieldName) {
          if (value === null || value === undefined) {
            delete relation[<'fields' | 'ends'>fieldType][fieldName];
          } else {
            relation[<'fields' | 'ends'>fieldType][fieldName] = <JsonField>value;
          }
        } else {
          if (value === null || value === undefined) {
            delete relation[<'display-name' | 'style'>fieldType];
          } else {
            relation[<'display-name' | 'style'>fieldType] = <string>value;
          }
        }
        return relations;
      });
    }
  }

  public getRelations(): JsonRelation[] {
    return this.getJs()[JsonRootBlock.model][ModelType.relations];
  }

  public setRelations(jsonRelations: JsonRelation[]): void {
    this.getJs()[JsonRootBlock.model][ModelType.relations] = jsonRelations;
  }

  public getMetaEntities(): JsonMetaEntities {
    return this.getJs()[JsonRootBlock.metamodel][ModelType.entities];
  }

  public setMetaEntities(jsonMetaEntities: JsonMetaEntities): void {
    this.getJs()[JsonRootBlock.metamodel][ModelType.entities] = jsonMetaEntities;
  }

  public getMetaRelations(): JsonMetaRelations {
    return this.getJs()[JsonRootBlock.metamodel][ModelType.relations];
  }

  public setMetaRelations(jsonMetaRelations: JsonMetaRelations): void {
    this.getJs()[JsonRootBlock.metamodel][ModelType.relations] = jsonMetaRelations;
  }

  public setDocument(jsonDocumentBlocks: JsonDocumentBlocks[] | BlocksReplacer<JsonDocumentBlocks[]>): void {
    if (Array.isArray(jsonDocumentBlocks)) {
      this.getJs()[JsonRootBlock.document] = jsonDocumentBlocks;
    } else {
      this.getJs()[JsonRootBlock.document] = jsonDocumentBlocks(this.getDocument());
    }
  }

  public getYaml(): string {
    this.reloadStateFromEngine();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.stateYaml!;
  }

  protected constructor() {
    // empty
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.yamState = YamlModelState.getInstance();

import { Schema } from 'yup';
import omit from 'lodash/omit';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
import { BaseBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/baseBlock';
import { AnyObject } from '@baseModel/utils/dataJuggler';

export function getJSON<T>(json: string | T): T {
  return typeof json === 'string' ? (JSON.parse(json) as T) : json;
}

export interface JsonDocumentBlock extends AnyObject {
  id: string;
  type: string;
}

export class Block<T extends AnyObject = AnyObject> extends BaseBlock<T> {
  constructor(valueSchema: Schema, type: string, id: string) {
    super(valueSchema, type, id);
  }

  public static override getValueFromJSON(json: string | JsonDocumentBlock): Omit<JsonDocumentBlock, 'id' | 'type'> {
    return replaceKeys(omit(getJSON(json), ['id', 'type']), camelCase) as Omit<JsonDocumentBlock, 'id' | 'type'>;
  }

  public static override getValueInJSONForYAML(json: string | JsonDocumentBlock): Partial<JsonDocumentBlock> {
    return replaceKeys(getJSON(json), kebabCase) as Partial<JsonDocumentBlock>;
  }

  public static override fromJSON(json: string | JsonDocumentBlock): Block {
    const jsonObj = getJSON(json);
    const block = new Block(Block.valueSchema, jsonObj.type, jsonObj.id);

    block.setValue(Block.getValueFromJSON(jsonObj));

    return block;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()},type=${
      this.type
    }]\n;;; ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] *here be dragons* \n;;; \n:::`;
  }
}

function replaceKeys(obj: AnyObject, replacer: (key: string) => string): AnyObject {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const newObj: AnyObject = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    const newKey = replacer(key);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    newObj[newKey] = replaceKeys(obj[key], replacer);
  });

  return newObj;
}

import { TextField, TextFieldProps, TextFieldWrapper } from '@smwb/summer-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldBasic } from '../hooks/useField';
import { DataJuggler, DataStoreName, EmptyObject } from '../utils/dataJuggler';
import { JsonField } from '../types/jsonDescription';
import { Models } from '../engine/types';
import { SimpleValueType } from '../types/simpleValueType';

export interface TextFieldEntityProps extends TextFieldProps {
  modelType: Models;
  modelName: string;
  fieldName: string;
  dataStoreName: DataStoreName;
  fieldPart?: keyof JsonField;
  sender?: string | EmptyObject;
}

export function TextFieldEntityBasicEditable({
  modelType,
  modelName,
  fieldName,
  dataStoreName,
  fieldPart,
  sender,
  onFocus,
  ...textFieldProps
}: TextFieldEntityProps) {
  // с типами все в порядке, видимо бабель у eslint криво загружает
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const { value, setValue } = useFieldBasic(modelType, modelName, fieldName, dataStoreName, sender);
  const [error, setError] = useState<string>('');
  let fieldValue: string | number | boolean | undefined;
  // common всегда string
  const isComplexValue =
    (fieldPart || (!!value && typeof value !== 'string')) &&
    (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel);
  if (isComplexValue) {
    if (fieldPart) {
      fieldValue = (value as JsonField)?.[fieldPart];
    } else {
      throw new Error(`fieldPart is required for EntityMetaModel and RelationMetaModel ${value}`);
    }
  } else {
    fieldValue = value as SimpleValueType;
  }

  useEffect(() => {
    if (
      editableRef.current &&
      editableRef.current.innerHTML !== fieldValue &&
      document.activeElement !== editableRef.current
    ) {
      editableRef.current.innerHTML = (fieldValue || '').toString();
    }
  }, [fieldValue]);

  const editableRef = useRef<HTMLSpanElement>(null);

  return (
    <TextFieldWrapper {...textFieldProps}>
      <span
        ref={editableRef}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onFocus={onFocus}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        style={{ wordWrap: 'anywhere', whiteSpace: 'pre-line' }}
        //{...textFieldProps}
        contentEditable
        // error={!!error}
        // helperText={error}
        // value={typeof fieldValue === 'boolean' ? fieldValue.toString() : fieldValue}
        onInput={(e) => {
          // console.log('e', e.currentTarget.textContent);
          if (isComplexValue) {
            try {
              (setValue as typeof DataJuggler.prototype.setFieldValue)(
                fieldName,
                {
                  ...(value as JsonField),
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  [fieldPart!]: e.currentTarget.textContent
                },
                sender
              );
            } catch (e: unknown) {
              setError((e as Error).message);
              return;
            }
          } else {
            try {
              (setValue as typeof DataJuggler.prototype.setFieldValue)(fieldName, e.currentTarget.textContent);
            } catch (e: unknown) {
              setError((e as Error).message);
              return;
            }
          }
          if (error) {
            setError('');
          }
        }}
        // dangerouslySetInnerHTML={{ __html: editableFieldValue }}
      />
    </TextFieldWrapper>
  );
}

export function TextFieldEntityBasic({
  modelType,
  modelName,
  fieldName,
  dataStoreName,
  fieldPart,
  sender,
  ...textFieldProps
}: TextFieldEntityProps) {
  // с типами все в порядке, видимо бабель у eslint криво загружает
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const { value, setValue } = useFieldBasic(modelType, modelName, fieldName, dataStoreName, sender);
  const [error, setError] = useState<string>('');
  let fieldValue: string | number | boolean | undefined;

  // common всегда string
  const isComplexValue =
    (fieldPart || (!!value && typeof value !== 'string')) &&
    (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel);
  if (isComplexValue) {
    if (fieldPart) {
      fieldValue = (value as JsonField)?.[fieldPart];
    } else {
      throw new Error(`fieldPart is required for EntityMetaModel and RelationMetaModel ${value}`);
    }
  } else {
    fieldValue = value as SimpleValueType;
  }

  return (
    <TextField
      {...textFieldProps}
      error={!!error}
      helperText={error}
      value={typeof fieldValue === 'boolean' ? fieldValue.toString() : fieldValue}
      onChange={(e) => {
        if (isComplexValue) {
          try {
            (setValue as typeof DataJuggler.prototype.setFieldValue)(
              fieldName,
              {
                ...(value as JsonField),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [fieldPart!]: e.target.value
              },
              sender
            );
          } catch (e: unknown) {
            setError((e as Error).message);
            return;
          }
        } else {
          try {
            (setValue as typeof DataJuggler.prototype.setFieldValue)(fieldName, e.target.value);
          } catch (e: unknown) {
            setError((e as Error).message);
            return;
          }
        }
        if (error) {
          setError('');
        }
      }}
    />
  );
}

export function TextFieldEntity({ ...props }: Omit<TextFieldEntityProps, 'dataStoreName'>) {
  return localStorage.tableEditable !== '0' ? (
    <TextFieldEntityBasicEditable {...props} dataStoreName={DataStoreName.fields} />
  ) : (
    <TextFieldEntityBasic {...props} dataStoreName={DataStoreName.fields} />
  );
}

export function TextCommonEntity({ ...props }: Omit<TextFieldEntityProps, 'dataStoreName'>) {
  return localStorage.tableEditable !== '0' ? (
    <TextFieldEntityBasicEditable {...props} dataStoreName={DataStoreName.common} />
  ) : (
    <TextFieldEntityBasic {...props} dataStoreName={DataStoreName.common} />
  );
}

export function TextRelationEntity({ ...props }: Omit<TextFieldEntityProps, 'dataStoreName'>) {
  return localStorage.tableEditable !== '0' ? (
    <TextFieldEntityBasicEditable {...props} dataStoreName={DataStoreName.relations} />
  ) : (
    <TextFieldEntityBasic {...props} dataStoreName={DataStoreName.relations} />
  );
}

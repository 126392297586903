import React from 'react';
import { ObjectSchema } from 'yup';
import { Engine } from '@baseModel/engine/engine';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { Config } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/view/config';
import { Editor } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/view/editor';
import { Block } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';

export type ConfigSave<T extends AnyObject = AnyObject> = BaseWidget<T>['save'];

export interface EditorViewProps<T extends AnyObject = AnyObject> {
  configData: T;
  engine: Engine;
}

export interface ConfigViewProps<T extends AnyObject = AnyObject> {
  initData: T;
  onSave: ConfigSave<T>;
  engine: Engine;
  isNew?: boolean;
}

export interface ConfigViewWrapperProps {
  onSubmit?: () => void;
}

/**
 * Базовый класс виджета со всем функционалом.
 * Для однотипности, любое значение блока должно быть **литералом объекта**.
 */
export abstract class BaseWidget<T extends AnyObject = AnyObject> {
  private readonly engine = Engine.getInstance();

  /**
   * Схема валидации значения блока.
   */
  public abstract valueSchema: ObjectSchema<Partial<T>>;

  constructor(private readonly blockId?: string, private readonly sortIndex?: number) {}

  /**
   * Уникальное имя виджета, используется для `type` блока документа.
   */
  public abstract getName(): string;

  public getType(): string {
    return `cs-${this.getName()}`;
  }

  /**
   * Если вернётся значение `false`, то предотвратит дальнейшее выполнение добавления. Вызывается до добавления/изменения.
   */
  // public async beforeSave?(): Promise<boolean>;

  /**
   * Вызывается после добавления/изменения.
   */
  // public async afterSave?(): Promise<void>;

  /**
   * Если вернётся значение `false`, то предотвратит дальнейшее выполнение удаления. Вызывается до удаления.
   */
  // public async beforeDelete?(): Promise<boolean>;

  /**
   * Вызывается после удаления.
   */

  // public async afterDelete?(): Promise<void>;

  /**
   * Используется для названия пункта в меню редактора.
   */
  public abstract getMenuItem(): string;

  /**
   * Получает изначальные данные. Они могут получаться с удалённого источника.
   */
  protected abstract getInitConfig(): Promise<T>;

  /**
   * Определяет, должен ли показываться компонент в `getConfigView` перед тем как добавится блок.
   */
  public askInitConfig(): boolean {
    return true;
  }

  /**
   * Этот метод ответственен за представление в редакторе. Для виджета, не относящегося к внешнему узлу редактора,
   * необязательно для реализации.
   */
  public getEditorView(): React.FC<EditorViewProps<T>> | null {
    return null;
  }

  /**
   * Этот метод необходим для настройки компонента представления (создания/редактирования). По своей сути должен
   * возвращать форму без какой-либо интерактивности. Для виджета, не относящегося к внешнему узлу редактора,
   * необязательно для реализации.
   */
  public getConfigView(): React.FC<ConfigViewProps<T>> | null {
    return null;
  }

  private isNew(): boolean {
    return !(this.blockId && this.engine.getDocument().getBlockById(this.blockId));
  }

  public async delete(): Promise<void> {
    return new Promise(() => {
      if (!this.blockId) {
        console.error('В конструктор не был передан blockId');
        return;
      }

      const document = this.engine.getDocument();
      document.removeBlockById(this.getType(), this.blockId, this.constructor.name);
    });
  }

  public async save(data?: T): Promise<void> {
    if (!this.blockId) {
      console.error('В конструктор не был передан blockId');
      return;
    }

    const value = data ?? (await this.getInitConfig());
    const document = this.engine.getDocument();
    const block = document.getBlockById(this.blockId) as unknown as Block<T>;

    if (block === undefined) {
      const type = this.getType();
      const newBlock = new Block(this.valueSchema, type, this.blockId);
      newBlock.setValue(value);

      document.addBlock(newBlock, this.sortIndex);
    } else {
      block.setValue(value);
    }
  }

  public getBlock(): typeof Block<T> {
    return Block<T>;
  }

  /**
   * Обёртка над компонентом `getEditorView`.
   */
  public getEditorViewWrapper(): ReturnType<React.FC<EditorViewProps<T>>> | null {
    const editorView = this.getEditorView();

    if (editorView === null) {
      return null;
    }

    return <Editor<T> Component={editorView} engine={this.engine} blockId={this.blockId} />;
  }

  /**
   * Обёртка над компонентом `getConfigView`.
   */
  public getConfigViewWrapper(props?: ConfigViewWrapperProps): ReturnType<React.FC<ConfigViewProps<T>>> | null {
    const configView = this.getConfigView();

    if (configView === null) {
      return null;
    }

    const getInitConfig = this.getInitConfig.bind(this);
    const save = this.save.bind(this);
    const isNew = this.isNew();

    return (
      <Config<T>
        Component={configView}
        getInitConfig={getInitConfig}
        engine={this.engine}
        onSave={save}
        onSubmit={props?.onSubmit}
        isNew={isNew}
        blockId={this.blockId}
      />
    );
  }
}

export const ENTITY_MODEL = {
  // имя entity метамодели
  name: 'entity',
  // имя relation метамодели связи модели с ее полями
  fieldRelation: 'entity-field',
  // Связь поля с entity
  fieldEndName: 'entity',
  // название будущей метамодели
  commonName: 'name',
  displayName: 'display-name',
  description: 'description'
};
export const RELATION_MODEL = {
  // имя entity метамодели
  name: 'relation',
  // имя relation метамодели связи модели с ее полями
  fieldRelation: 'relation-field',
  fieldEndName: 'relation',
  // имя relation метамодели связи модели с ее связями
  entityRelation: 'entity-relation',
  relationEndName: 'relation',
  entityEndName: 'entity',
  // название будущей метамодели
  commonName: 'name',
  displayName: 'display-name',
  description: 'description'
};
export const FIELD_MODEL = {
  name: 'field',
  fieldEndName: 'field'
};

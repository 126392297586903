import { object, ObjectSchema, mixed, string } from 'yup';
import { Block } from './block';
import omit from 'lodash/omit';
import { BlockType } from './types';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
import { JsonDocumentActionBlock, JsonDocumentActionBlockTypes } from '../../types/jsonDescription';

export type ActionValue = Omit<JsonDocumentActionBlock, 'id' | 'type'>;

const actionSchema: ObjectSchema<Omit<ActionValue, 'value'>> = object().shape({
  title: string().required(),
  value: mixed().oneOf([JsonDocumentActionBlockTypes.generateMetaModel]).required()
});

/**
 * Разрешенные действия над документом
 */
export class Action extends Block<ActionValue> {
  public override valueSchema = actionSchema;

  constructor(id: string) {
    super(BlockType.action, id);
  }

  public static override getValueFromJSON(json: string | JsonDocumentActionBlock): ActionValue {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentActionBlock) : json;
    return omit(jsonObj, ['id', 'type']);
  }

  public static override fromJSON(json: string | JsonDocumentActionBlock): Action {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentActionBlock) : json;
    const customNode = new Action(jsonObj.id);
    customNode.setValue(Action.getValueFromJSON(jsonObj));
    return customNode;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()},type=${
      this.type
    }]\n;;; ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] *here be dragons* \n;;; \n:::`;
  }
}

import React, { useState } from 'react';
import { useAction } from '@baseModel/document/hooks/useAction';
import { JsonDocumentActionBlockTypes } from '@baseModel/types/jsonDescription';
import { Button, useSnackbar } from '@smwb/summer-ui';
import { GenerateMetaModel } from '@baseModel/document/actions/generateMetaModel';
import { Engine } from '@baseModel/engine/engine';
import { useMediaQuery } from '@hooks/useMediaQuery';

export interface ActionButtonProps {
  id: string;
}

export function ActionButton({ id }: ActionButtonProps) {
  const [action] = useAction(id);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const { addSnackbar } = useSnackbar();
  const [inProgress, setInProgress] = useState(false);

  if (action && action.value === JsonDocumentActionBlockTypes.generateMetaModel) {
    const generate = () => {
      setInProgress(true);
      new GenerateMetaModel(Engine.getInstance())
        .generate()
        .then((id: string) => {
          const url = window.location.href.replace(/\?file=.*$/, '') + '?file=' + id;
          window.open(url, '_blank');
        })
        .catch((e: Error) => {
          addSnackbar({
            message: e.message,
            position: isMobile ? 'bottom' : 'top-right',
            variant: 'danger'
          });
        })
        .finally(() => {
          setInProgress(false);
        });
    };
    return <Button disabled={inProgress} label={action.title} onClick={generate} />;
  }

  return null;
}
